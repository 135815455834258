import { Card, Grid, CardContent, Typography } from '@rentacenter/racstrap';
import React, { useContext } from 'react';
import { InventoryDetailsContext } from '../../../../context/Inventory/InventoryDetailsContext';
import {
  formatCurrency,
  isValuePresent,
} from '../../../../utils/InventoryUtils';

export function InventoryInformation() {
  const { classes, inventoryInformation, depreciationEstimator } = useContext(
    InventoryDetailsContext
  );

  const firstRow = () => (
    <Grid container md={12} className={`${classes.cardFirstRow}`}>
      <Grid item xs={12} sm={4} md={2} lg={2}>
        <Typography className={`${classes.formLabel}`}>
          Serial Number
        </Typography>
        <Typography
          className={`${classes.formValue}`}
          noWrap
          title={
            inventoryInformation.serialNumber
              ? inventoryInformation.serialNumber
              : ''
          }
        >
          {inventoryInformation.serialNumber
            ? inventoryInformation.serialNumber
            : '-'}
        </Typography>
      </Grid>

      <Grid item xs={12} sm={4} md={2} lg={2}>
        <Typography className={`${classes.formLabel}`}>Model Number</Typography>
        <Typography
          className={`${classes.formValue}`}
          noWrap
          title={
            inventoryInformation.modelNumber
              ? inventoryInformation.modelNumber
              : ''
          }
        >
          {inventoryInformation.modelNumber
            ? inventoryInformation.modelNumber
            : '-'}
        </Typography>
      </Grid>

      <Grid item xs={12} sm={4} md={2} lg={2}>
        <Typography className={`${classes.formLabel}`}>Department</Typography>
        <Typography
          className={`${classes.formValue}`}
          noWrap
          title={
            inventoryInformation.department
              ? inventoryInformation.department
              : ''
          }
        >
          {inventoryInformation.department
            ? inventoryInformation.department
            : '-'}
        </Typography>
      </Grid>

      <Grid item xs={12} sm={4} md={2} lg={2}>
        <Typography className={`${classes.formLabel}`}>
          Sub Department
        </Typography>
        <Typography
          className={`${classes.formValue}`}
          noWrap
          title={
            inventoryInformation.subDepartment
              ? inventoryInformation.subDepartment
              : ''
          }
        >
          {inventoryInformation.subDepartment
            ? inventoryInformation.subDepartment
            : '-'}
        </Typography>
      </Grid>

      <Grid item xs={12} sm={4} md={2} lg={2}>
        <Typography className={`${classes.formLabel}`}>Brand</Typography>
        <Typography
          className={`${classes.formValue}`}
          noWrap
          title={inventoryInformation.brand ? inventoryInformation.brand : ''}
        >
          {inventoryInformation.brand ? inventoryInformation.brand : '-'}
        </Typography>
      </Grid>

      <Grid item xs={12} sm={4} md={2} lg={2}>
        <Typography className={`${classes.formLabel}`}>Bracket</Typography>
        <Typography
          className={`${classes.formValue}`}
          noWrap
          title={
            inventoryInformation.bracket ? inventoryInformation.bracket : ''
          }
        >
          {inventoryInformation.bracket ? inventoryInformation.bracket : '-'}
        </Typography>
      </Grid>
    </Grid>
  );

  const secondRow = () => (
    <Grid container md={12} className={`${classes.cardFirstRow}`}>
      <Grid item xs={12} sm={4} md={2} lg={2}>
        <Typography className={`${classes.formLabel}`}>Current Term</Typography>
        <Typography className={`${classes.formValue}`}>
          {inventoryInformation.currentTerm
            ? inventoryInformation.currentTerm
            : '-'}
        </Typography>
      </Grid>

      <Grid item xs={12} sm={4} md={2} lg={2}>
        <Typography className={`${classes.formLabel}`}>Current Rate</Typography>
        <Typography className={`${classes.formValue}`}>
          {inventoryInformation.currentRate
            ? formatCurrency(inventoryInformation.currentRate)
            : '-'}
        </Typography>
      </Grid>

      <Grid item xs={12} sm={4} md={2} lg={2}>
        <Typography className={`${classes.formLabel}`}>Invoice #</Typography>
        <Typography className={`${classes.formValue}`}>
          {inventoryInformation.invoiceNumber
            ? inventoryInformation.invoiceNumber
            : '-'}
        </Typography>
      </Grid>

      <Grid item xs={12} sm={4} md={2} lg={2}>
        <Typography className={`${classes.formLabel}`}>
          Accounting date
        </Typography>
        <Typography className={`${classes.formValue}`}>
          {inventoryInformation.invoiceDate
            ? inventoryInformation.invoiceDate
            : '-'}
        </Typography>
      </Grid>

      <Grid item xs={12} sm={4} md={2} lg={2}>
        <Typography className={`${classes.formLabel}`}>Store #</Typography>
        <Typography className={`${classes.formValue}`}>
          {inventoryInformation?.storeNumber
            ? inventoryInformation.storeNumber
            : '-'}
        </Typography>
      </Grid>

      <Grid item xs={12} sm={4} md={2} lg={2}>
        <Typography className={`${classes.formLabel}`}>Days Idle</Typography>
        <Typography className={`${classes.formValue}`}>
          {isValuePresent(depreciationEstimator?.daysIdle)
            ? depreciationEstimator.daysIdle
            : '-'}
        </Typography>
      </Grid>
    </Grid>
  );

  return (
    <>
      <Grid style={{ marginBottom: '10px' }}>
        <Card className={`${classes.informationCard}`}>
          <CardContent>
            {firstRow()}
            {secondRow()}

            <Grid container md={12} className={`${classes.cardLastRow}`}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Typography className={`${classes.formLabel}`}>
                  Audit Message
                </Typography>
                <Typography className={`${classes.formValue}`} noWrap>
                  {inventoryInformation.auditMessage &&
                  inventoryInformation.auditMessage !== ''
                    ? inventoryInformation.auditMessage
                    : '-'}
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </>
  );
}
