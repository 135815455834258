/* eslint-disable */
import {
  Card,
  CardContent,
  CircularProgress,
  Grid,
  RACModalCard,
  RACTable,
  RACTableCell,
  RACTableRow,
} from '@rentacenter/racstrap';
import { GlobalStyles } from '../../../JsStyles/globalStyles';
import React, { useContext } from 'react';
import { AgreementContext } from './../AgreementContext';
import ErrorContent from './../ErrorMessageContent';
import InfiniteScroll from 'react-infinite-scroll-component';
import { ReactComponent as MoreInfo } from '../../../Icons/More info.svg';
import { ReactComponent as ViewPdf } from '../../../Icons/View PDF.svg';
import { paymentHeadings } from '../../../constants/constants';
import { getDocument, getpaymentHistory } from '../../../api/user';
import { ReactComponent as Sort } from '../../../Icons/Sort.svg';
import { formatePayment, sorting, viewPDFclick } from '../commonFunctions';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { PaymentDetailsCard } from './AgrPaymentPopUp';

// eslint-disable-next-line sonarjs/cognitive-complexity
export default function AgrPayment(props: any) {
  const classes = GlobalStyles();
  const {
    loaders,
    setLoaders,
    paymentPageDisplay,
    sortOrder,
    hasMore,
    setHasMore,
    paymentDetails,
    setPaymentDetails,
    setPaymentRecieptIndex,
    setPaymentPopUp,
    setReceiptAlert,
    setSortOrder,
    setActivityDetails,
    setInventoryDetails,
    paymentPopUp,
    receiptAlert,
  } = useContext(AgreementContext);
  const history = useHistory();
  const search = useLocation().search;
  const queryParams = new URLSearchParams(search);
  const agrType = queryParams.get('agrType');
  const params: any = useParams();

  function addPayments(totalPayment: any, clubAmount: any) {
    const totalPaymentValue = parseFloat(totalPayment) || 0;
    const clubAmountValue = parseFloat(clubAmount) || 0;
    const result = totalPaymentValue + clubAmountValue;
    return formatePayment(result.toFixed(2));
  }

  const paymentTableContentHelper = (obj: any, index: any) => (
    <>
      <RACTableCell
        className={`${classes.textDarkSlateBlue} ${classes.semiBoldFont} ${classes.whitebackground}`}
      >
        {obj?.daysLate ? obj?.daysLate : '0'}
      </RACTableCell>
      <RACTableCell
        className={`${classes.textDarkSlateBlue} ${classes.semiBoldFont} ${classes.whitebackground}`}
      >
        {obj?.nsf ? obj?.nsf : 'No'}
      </RACTableCell>
      <RACTableCell
        className={`${classes.textDarkSlateBlue} ${classes.semiBoldFont} ${classes.whitebackground}`}
      >
        {obj?.totalPayment
          ? addPayments(obj?.totalPayment, obj?.clubAmount)
          : '$0.00'}
      </RACTableCell>
      <RACTableCell className={`${classes.width0} ${classes.whitebackground}`}>
        <MoreInfo
          className={`${classes.imgHeight}`}
          onClick={() => {
            setPaymentRecieptIndex(index);
            setPaymentPopUp(true);
          }}
        />
        <ViewPdf
          className={`${classes.mL1} ${classes.imgHeight}`}
          onClick={() => {
            viewDocument(obj?.receiptId);
          }}
        />
      </RACTableCell>
    </>
  );

  const paymentTableContentFn = () => (
    <>
      {paymentDetails?.map((obj: any, index: any) => {
        return (
          <RACTableRow key={index}>
            <RACTableCell
              className={`${classes.textDarkSlateBlue} ${classes.whitebackground} ${classes.semiBoldFont}`}
            >
              {obj?.datePaid
                ? obj?.datePaid + ' ' + obj?.timePaid + ' ' + 'CST'
                : '-'}
            </RACTableCell>
            <RACTableCell
              className={`${classes.textDarkSlateBlue} ${classes.semiBoldFont} ${classes.whitebackground}`}
            >
              {obj?.receiptId ? obj?.receiptId : '-'}
            </RACTableCell>
            <RACTableCell
              className={`${classes.textBlue} ${classes.semiBoldFont} ${classes.cursorStyle} ${classes.whitebackground}`}
              onClick={() => {
                history.push({
                  pathname: `/operations/store/${obj?.store}`,
                });
              }}
            >
              {obj?.store ? obj?.store : '-'}
            </RACTableCell>
            <RACTableCell
              className={`${classes.textDarkSlateBlue} ${classes.semiBoldFont} ${classes.whitebackground}`}
            >
              {obj?.paymentOrigin ? obj?.paymentOrigin : '-'}
            </RACTableCell>
            <RACTableCell
              className={`${classes.textDarkSlateBlue} ${classes.semiBoldFont} ${classes.whitebackground}`}
            >
              {obj?.tenderType ? obj?.tenderType : '-'}
            </RACTableCell>
            <RACTableCell
              className={`${classes.textDarkSlateBlue} ${classes.semiBoldFont} ${classes.whitebackground}`}
            >
              {obj?.cC4Digit ? obj?.cC4Digit : '-'}
            </RACTableCell>
            <RACTableCell
              className={`${classes.textDarkSlateBlue} ${classes.semiBoldFont} ${classes.whitebackground}`}
            >
              {obj?.daysExt ? obj?.daysExt : '0'}
            </RACTableCell>
            {paymentTableContentHelper(obj, index)}
          </RACTableRow>
        );
      })}
    </>
  );

  const fetchNextData = async () => {
    try {
      setHasMore(true);
      setLoaders({ ...loaders, fetchDataLoader: true });
      let paymentHistory =
        agrType == 'club'
          ? await getpaymentHistory(
              params.customerId,
              'club',
              props.offset,
              params.agreementId
            )
          : await getpaymentHistory(
              params.customerId,
              'agr',
              props.offset,
              params.agreementId
            );
      props.setOffset(props.offset + 25);
      if (paymentHistory.status == 200 || paymentHistory.status == 204) {
        agrType == 'club'
          ? (paymentHistory = paymentHistory?.data?.value?.clubHistoryGridInfo)
          : (paymentHistory =
              paymentHistory?.data?.value?.paymentHistoryGridInfo);
        setLoaders({ ...loaders, fetchDataLoader: false });
        setPaymentDetails((pre: any) => [...pre, ...paymentHistory]);
        if (paymentHistory?.length !== 25) {
          setHasMore(false);
        }
      } else {
        setHasMore(false);
      }
    } catch (e: any) {
      setLoaders({ ...loaders, fetchDataLoader: false });
      setHasMore(false);
    }
  };

  const paymentTableHeadFn = () => (
    <>
      {paymentHeadings.map((obj: any, index: any) => {
        return paymentDetails.length > 1 && obj?.heading === 'Date' ? (
          <RACTableCell key={index}>
            {obj?.heading}{' '}
            {!sortOrder.dateSortOrder ? (
              <Sort
                className={`${classes.sortUp}`}
                onClick={() =>
                  sorting(
                    paymentDetails,
                    'desc',
                    'datePaid',
                    setSortOrder,
                    sortOrder,
                    setPaymentDetails,
                    setActivityDetails,
                    setInventoryDetails
                  )
                }
              />
            ) : (
              <Sort
                className={`${classes.sort}`}
                onClick={() =>
                  sorting(
                    paymentDetails,
                    'asc',
                    'datePaid',
                    setSortOrder,
                    sortOrder,
                    setPaymentDetails,
                    setActivityDetails,
                    setInventoryDetails
                  )
                }
              />
            )}
          </RACTableCell>
        ) : (
          <RACTableCell key={index}>{obj?.heading}</RACTableCell>
        );
      })}
    </>
  );

  async function viewDocument(id: string) {
    try {
      setLoaders({ ...loaders, recpLoader: true });
      const printResult = await getDocument(id, 'RCP');
      setLoaders({ ...loaders, recpLoader: false });
      if (printResult?.status == 200) {
        if (printResult?.data && printResult?.data?.fileList) {
          const responseArray = printResult?.data?.fileList;
          const documentB64 = responseArray.find(
            (obj: any) => obj['docType'] === 'PRCT'
          );
          if (documentB64 && documentB64?.fileObject) {
            viewPDFclick(documentB64?.fileObject, documentB64?.fileName);
          } else {
            setReceiptAlert(true);
          }
        } else {
          setReceiptAlert(true);
        }
      } else {
        setReceiptAlert(true);
      }
    } catch (error: any) {
      setReceiptAlert(true);
    }
  }

  return (
    <>
      <Grid
        container
        md={12}
        className={`${classes.globalBackgroundColor} ${classes.padding1} ${classes.paddingBottom70px}`}
      >
        <Card
          className={paymentDetails?.length > 24 ? classes.card1 : classes.card2}
        >
          <CardContent>
            {paymentPageDisplay == 204 || paymentPageDisplay == 400 ? (
              <>
                <ErrorContent
                  content={'No Records Found'}
                  status={204}
                  style={classes.mt3}
                />
              </>
            ) : paymentPageDisplay == 200 ? (
              paymentDetails?.length > 24 ? (
                <InfiniteScroll
                  className={`${classes.hideScrollbar}`}
                  next={fetchNextData}
                  dataLength={paymentDetails?.length}
                  hasMore={hasMore}
                  height={`${!hasMore ? ((paymentDetails?.length + 1) * 52) : ((paymentDetails?.length * 52) - 100)}px` || 'auto'}
                  loader={
                    loaders.fetchDataLoader ? (
                      <Grid className={classes.GridLoader}>
                        <CircularProgress></CircularProgress>
                      </Grid>
                    ) : null
                  }
                >
                  <RACTable
                    id="table-id"
                    className={classes.fixTableHeadAgrTrsRec}
                    renderTableHead={() => paymentTableHeadFn()}
                    renderTableContent={paymentTableContentFn}
                    stickyHeader
                  ></RACTable>
                </InfiniteScroll>
              ) : (
                <RACTable
                  className={classes.fixTableHeadAgrTrsRec}
                  renderTableHead={() => paymentTableHeadFn()}
                  renderTableContent={paymentTableContentFn}
                  stickyHeader
                ></RACTable>
              )
            ) : paymentPageDisplay == 400 ? (
              <>
                <ErrorContent
                  content={'Something Went Wrong'}
                  status={500}
                  style={classes.mt3}
                />
              </>
            ) : (
              <></>
            )}
          </CardContent>
        </Card>
      </Grid>

      <RACModalCard
        isOpen={receiptAlert}
        closeIcon={true}
        title={'Receipt'}
        onClose={() => {
          setReceiptAlert(false), setLoaders({ ...loaders, docLoader: false });
        }}
        maxWidth="xs"
        className={`${classes.customModal1}`}
        borderRadius={'15px'}
      >
        <ErrorContent
          content={'Not able to Get Receipt information'}
          status={500}
          style={''}
        />
      </RACModalCard>

      <RACModalCard
        isOpen={paymentPopUp}
        closeIcon={true}
        title="Payment Details"
        onClose={() => {
          setPaymentPopUp(false);
        }}
        maxWidth="sm"
        className={`${classes.paymentPopup}`}
      >
        {<PaymentDetailsCard />}
      </RACModalCard>
    </>
  );
}
