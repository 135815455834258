import React, { useContext, useEffect, useState } from 'react';
import {
  Grid,
  RACButton,
  RACModalCard,
  CircularProgress,
  RACTabs,
} from '@rentacenter/racstrap';
import { GlobalStyles } from '../../../JsStyles/globalStyles';
import {
  GetAgreementactivity,
  GetCustomerCoverage,
  getAgreementInfo,
  getPrintDocument,
  getpaymentHistory,
} from '../../../api/user';
import { AgreementContext } from './../AgreementContext';
import AgrPayment from './AgrDetailsPayment';
import { useLocation, useParams } from 'react-router-dom';
import AgrInventory from './AgrDetailsInventory';
import SomethingWentWrongPopUp from '../SomethingWentWrong';
import AgrActivity from './AgrDetailsActivity';
import AgrContent from './AgrDetailsContent';
import AgrClubContent from './AgrDetailsClubContent';
import AgrDocument from './AgrDocumentPopup';

export default function AgreementDetails() {
  const {
    loaders,
    setLoaders,
    agreementPageDisplay,
    setAgreementPageDisplay,
    setInventoryPageDisplay,
    setPaymentPageDisplay,
    setActivityPageDisplay,
    agreementErrorPopupOpen,
    setAgreementErrorPopupOpen,
    agreementData,
    setAgreementData,
    clubData,
    setClubData,
    validPrilimStatus,
    setInventoryDetails,
    setPaymentDetails,
    setActivityDetails,
    doc,
    setdoc,
    history,
    setDocumentPageDisplay,
    setDocumentData,
    setReceiptAlert,
  } = useContext(AgreementContext);
  const classes = GlobalStyles();
  const params: any = useParams();
  const agreementId = params.agreementId?.split('?')[0];
  const payHistory = 'Payment History';
  const search = useLocation().search;
  const queryParams = new URLSearchParams(search);
  const agrType = queryParams.get('agrType');
  const storeNumber = queryParams.get('storeNumber');
  const [offset, setOffset] = useState<number>(25);

  useEffect(() => {
    getAgreementDetails();
    viewDocument();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  let agreementDetails: any = {};
  let activityData: any = {};
  let paymentHistoryData: any = {};
  let clubDetails: any = {};
  let customer: any = '';
  let coCustomer: any = '';
  async function getAgreementDetails() {
    try {
      setLoaders({ ...loaders, pageLoader: true });
      setAgreementPageDisplay(0);
      if (agrType != 'club') {
        agreementDetails = await getAgreementInfo(agreementId);
        customer = agreementDetails?.data?.agreementCustomer.find(
          (val: any) => val.priority === '1'
        );
        coCustomer = agreementDetails?.data?.agreementCustomer.find(
          (val: any) => val.priority === '2'
        );
        if (Object.keys(agreementDetails).length === 0) {
          setAgreementPageDisplay(500);
          setAgreementErrorPopupOpen(true);
          setLoaders({ ...loaders, pageLoader: false });
        } else {
          [paymentHistoryData, activityData] = await Promise.all([
            getpaymentHistory(params.customerId, 'agr', 0, agreementId),
            GetAgreementactivity(agreementId),
          ]);
        }
      } else {
        [clubDetails, paymentHistoryData] = await Promise.all([
          GetCustomerCoverage(params.customerId, storeNumber),
          getpaymentHistory(params.customerId, 'club', 0, agreementId),
        ]);
        if (Object.keys(clubDetails).length === 0) {
          setAgreementPageDisplay(500);
          setAgreementErrorPopupOpen(true);
          setLoaders({ ...loaders, pageLoader: false });
        }
      }
      setLoaders({ ...loaders, pageLoader: false });

      if (agrType == 'club') {
        setClub();
      }
      setAgreement();
      setInventory();
      setPayment();
      setActivity();
    } catch (error: any) {
      setAgreementPageDisplay(500);
      setAgreementErrorPopupOpen(true);
      setLoaders({ ...loaders, pageLoader: false });
    }
  }

  async function viewDocument() {
    setDocumentPageDisplay(0);
    setReceiptAlert(false);
    const payLoad: any = {
      identifier: agrType == 'club' ? params.customerId : params.agreementId,
      documentStatus: 'signed',
      actionType: 'print',
      identifierType: agrType == 'club' ? 'CUST' : 'AGR',
    };
    const printDocumentResult = await getPrintDocument(payLoad);
    if (printDocumentResult?.status == 200) {
      setDocumentData(printDocumentResult?.data.documents);
      setDocumentPageDisplay(200);
    } else if (printDocumentResult?.status == 204) {
      setDocumentPageDisplay(204);
    } else {
      setDocumentPageDisplay(500);
    }
  }

  const getAgrStatus = (agreementStatus: string) => {
    switch (agreementStatus) {
      case 'A':
      case 'ACTIVE':
        return 'Active';
      case 'P':
      case 'PENDING':
        return 'Pending';
      case 'INACTIVE':
        return 'Inactive';
      default:
        return agreementStatus;
    }
  };

  const getNoteSchedule = (noteSchedule: string) => {
    switch (noteSchedule) {
      case 'MON':
        return 'Monthly';
      case 'WK':
        return 'Weekly';
      case 'BIWK':
        return 'Bi-Weekly';
      case 'SEMI':
        return 'Semi-Monthly ';
      default:
        return noteSchedule;
    }
  };

  const setClub = () => {
    if (clubDetails?.status == 200) {
      setAgreementPageDisplay(200);
      setClubData({
        ...clubData,
        membershipId: clubDetails?.data?.clubInfo?.membershipNumber,
        status: getAgrStatus(clubDetails?.data?.clubInfo?.clubStatus),
        dateOpened: clubDetails?.data?.clubInfo?.startDate,
        dueDate: clubDetails?.data?.clubInfo?.nextDueDate,
        schedule: clubDetails?.data?.clubInfo?.paymentSchedule,
        daysLate: clubDetails?.data?.clubInfo?.daysLate,
        autoPay: clubDetails?.data?.clubInfo?.isAutoPay == '0' ? 'No' : 'Yes',
        dateClosed: clubDetails?.data?.clubInfo?.closeDate,
        rate: clubDetails?.data?.clubInfo?.clubAmount,
        amountPaid: clubDetails?.data?.clubInfo?.amountPaid,
        customerName:
          paymentHistoryData?.data?.value?.customerInfo?.customerName,
        coCustomerName:
          paymentHistoryData?.data?.value?.customerInfo?.coCustomerName,
        coCustomerId:
          paymentHistoryData?.data?.value?.customerInfo?.coCustomerId,
      });
    } else {
      setAgreementPageDisplay(500);
      setAgreementErrorPopupOpen(true);
    }
  };

  const setAgreement = () => {
    if (agreementDetails?.status == 200) {
      setAgreementPageDisplay(200);
      setAgreementSuccess();
    } else if (agreementDetails.status == 204) {
      setAgreementPageDisplay(204);
    }
  };

  const setAgreementSuccess = () => {
    setAgreementData({
      ...agreementData,
      agreement: agreementDetails?.data?.agreementInformation?.agreementNumber,
      agreementType:
        agrType == 'notes'
          ? agreementDetails?.data?.agreementInformation?.noteType
          : agreementDetails?.data?.agreementInformation?.agreementType,
      description:
        agreementDetails?.data?.agreementInformation?.agreementDescription,
      coverage:
        agreementDetails?.data?.agreementInformation?.coverageTypeRefCode,
      reinstatement:
        agreementDetails?.data?.agreementWorkflow?.isReinstated == '0'
          ? 'No'
          : 'Yes',
      customerName: customer?.firstName + ' ' + customer?.lastName,
      coCustomerName: coCustomer
        ? coCustomer?.firstName + ' ' + coCustomer?.lastName
        : null,
      storeId: agreementDetails?.data?.agreementInformation?.storeNumber,
      customerId: customer?.customerId,
      status:
        agrType == 'notes'
          ? getAgrStatus(
              agreementDetails?.data?.installmentNoteInfo?.statusGroupRefCode
            )
          : agreementDetails?.data?.agreementInformation?.agrStatus,
      dateOpened:
        agrType == 'notes'
          ? agreementDetails?.data?.installmentNoteInfo?.openDate
          : agreementDetails?.data?.agreementInformation?.openDate,
      dateClosed:
        agrType == 'notes'
          ? agreementDetails?.data?.installmentNoteInfo?.closeDate
          : agreementDetails?.data?.agreementInformation?.closeDate,
      autoPay:
        agreementDetails?.data?.agreementWorkflow?.isAutopayEnabled == '0'
          ? 'No'
          : 'Yes',
      schedule:
        agrType == 'notes'
          ? getNoteSchedule(
              agreementDetails?.data?.installmentNoteInfo?.noteSchedule
            )
          : agreementDetails?.data?.agreementInformation?.paySchedule,
      epo: agreementDetails?.data?.agreementInformation?.epoAmount,
      term:
        agrType == 'notes'
          ? parseInt(agreementDetails?.data?.installmentNoteInfo?.noteTerm)
          : agreementDetails?.data?.totalPayment?.totalTerm,
      remainingTerm:
        agrType == 'notes'
          ? agreementDetails?.data?.installmentNoteInfo?.remainingScheduleTerm
          : agreementDetails?.data?.agreementInformation?.remainingTerm,
      totalRent: agreementDetails?.data?.agreementTotal?.agreementTotal,
      cashPrice: agreementDetails?.data?.totalPayment?.cashPrice,
      promotionApplied: agreementDetails?.data?.agreementInformation?.promoName,
      promoDesc: agreementDetails?.data?.agreementInformation?.promoDesc,
      rate:
        agrType == 'notes'
          ? agreementDetails?.data?.installmentNoteInfo?.noteRate
          : agreementDetails?.data?.agreementTotal?.agreementRate,
      totalCost: agreementDetails?.data?.installmentNoteInfo?.noteCost,
      eComm: agreementDetails?.data?.agreementInformation?.ecommOrdernumber,
      freeTimeAmount:
        agreementDetails?.data?.agreementInformation?.freeTimeAmount,
      freeTimeDays: agreementDetails?.data?.agreementInformation?.freeTimeDays,
      currentDueDate:
        agrType == 'notes'
          ? agreementDetails?.data?.installmentNoteInfo?.noteDueDate
          : agreementDetails?.data?.agreementInformation?.currentDueDate,
      currentDaysLate: agreementDetails?.data?.agreementInformation?.daysLate,
      closedReason:
        agrType == 'notes'
          ? agreementDetails?.data?.installmentNoteInfo?.closeReason
          : agreementDetails?.data?.agreementInformation?.agrStatusReason,
      rentPaid: agreementDetails?.data?.agreementInformation?.agrRentPaid,
      backRent: agreementDetails?.data?.agreementInformation?.backRent,
      notePaid: agreementDetails?.data?.installmentNoteInfo?.noteCostPaid,
      remainingNotes:
        agreementDetails?.data?.installmentNoteInfo?.remainingNoteCost,
      agreementRv: agreementDetails?.data?.agreementTotal?.agreementRv,
      coCustomerId: coCustomer ? coCustomer?.customerId : '',
    });
  };

  const setInventory = () => {
    if (
      agreementDetails?.status == 200 &&
      agreementDetails?.data?.agreementInventories &&
      agreementDetails?.data?.agreementInventories.length != 0
    ) {
      setInventoryPageDisplay(200);
      setInventoryDetails(agreementDetails?.data?.agreementInventories);
    } else if (
      agreementDetails?.status == 200 &&
      (!agreementDetails?.data?.agreementInventories ||
        agreementDetails?.data?.agreementInventories.length == 0)
    ) {
      setInventoryPageDisplay(204);
    } else {
      setInventoryPageDisplay(500);
    }
  };

  const setPayment = () => {
    if (paymentHistoryData.status == 200) {
      if (paymentHistoryData?.data?.value?.code == 500) {
        setAgreementErrorPopupOpen(false);
        setPaymentPageDisplay(500);
      } else if (paymentHistoryData?.data?.value?.code == 400) {
        setAgreementErrorPopupOpen(false);
        setPaymentPageDisplay(400);
      } else if (
        paymentHistoryData?.data?.value?.paymentHistoryGridInfo?.length !== 0 ||
        paymentHistoryData?.data?.value?.clubHistoryGridInfo?.length !== 0
      ) {
        setPaymentPageDisplay(200);
        agrType == 'club'
          ? setPaymentDetails(
              paymentHistoryData?.data?.value?.clubHistoryGridInfo
            )
          : setPaymentDetails(
              paymentHistoryData?.data?.value?.paymentHistoryGridInfo
            );
      } else {
        setPaymentPageDisplay(204);
      }
    } else if (paymentHistoryData.status == 204) {
      setPaymentPageDisplay(204);
    } else {
      setPaymentPageDisplay(500);
    }
  };

  const setActivity = () => {
    if (
      (activityData.status == 200 &&
        activityData?.data?.agreementActivities.length != 0) ||
      (paymentHistoryData.status == 200 &&
        paymentHistoryData?.data?.value?.clubActivityInfo?.length !== 0)
    ) {
      setActivityPageDisplay(200);
      agrType == 'club'
        ? setActivityDetails(paymentHistoryData?.data?.value?.clubActivityInfo)
        : setActivityDetails(activityData?.data?.agreementActivities);
    } else if (
      activityData.status == 204 ||
      (activityData.status == 200 &&
        activityData?.data?.agreementActivities.length == 0) ||
      paymentHistoryData?.data?.value?.clubActivityInfo.length == 0
    ) {
      setActivityPageDisplay(204);
    } else if (activityData.status == 400) {
      setActivityPageDisplay(400);
    } else {
      setActivityPageDisplay(500);
    }
  };

  const redirect = () => {
    history.goBack();
  };

  const renderTabs = () => (
    <Grid
      container
      md={12}
      className={`${classes.mt2} ${classes.whitebackground} ${classes.tabsWidth}`}
    >
      <RACTabs
        tabs={
          !validPrilimStatus.includes(agreementData?.status) &&
          agreementData?.status !== 'Pending' &&
          agrType != 'club' &&
          agrType != 'notes'
            ? ['Inventory', payHistory, 'Activity']
            : validPrilimStatus.includes(agreementData?.status) &&
              agrType != 'club' &&
              agrType != 'notes'
            ? ['RMS', payHistory, 'Activity']
            : [payHistory, 'Activity']
        }
        contentForTabs={
          !validPrilimStatus.includes(agreementData?.status) &&
          agreementData?.status !== 'Pending' &&
          agrType != 'club' &&
          agrType != 'notes'
            ? [
                <AgrInventory key="inventory" />,
                <AgrPayment
                  key="payment"
                  offset={offset}
                  setOffset={setOffset}
                />,
                <AgrActivity key="activity" />,
              ]
            : validPrilimStatus.includes(agreementData?.status) &&
              agrType != 'club' &&
              agrType != 'notes'
            ? [
                <AgrInventory key="inventory" />,
                <AgrPayment
                  key="payment"
                  offset={offset}
                  setOffset={setOffset}
                />,
                <AgrActivity key="activity" />,
              ]
            : [
                <AgrPayment
                  key="payment"
                  offset={offset}
                  setOffset={setOffset}
                />,
                <AgrActivity key="activity" />,
              ]
        }
        defaultTab={0}
      ></RACTabs>
    </Grid>
  );

  return (
    <Grid>
      {loaders.pageLoader ? (
        <>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            className={`${classes.height2}`}
          >
            <CircularProgress />
          </Grid>
        </>
      ) : (
        <></>
      )}
      {loaders.recpLoader ? (
        <>
          <RACModalCard
            className={classes.loaderModalCard}
            isOpen={loaders.recpLoader}
            closeIcon={false}
            maxWidth={'xs'}
            borderRadius={'25px !important'}
            TitleClassName={`${classes.centerAlign}`}
          >
            {
              <Grid className={`${classes.centerAlign}`}>
                <CircularProgress />
              </Grid>
            }
          </RACModalCard>
        </>
      ) : (
        <></>
      )}
      {agreementPageDisplay == 400 || agreementPageDisplay == 500 ? (
        <SomethingWentWrongPopUp tab={agreementErrorPopupOpen} />
      ) : (
        <></>
      )}
      {agreementPageDisplay == 200 ? (
        <Grid>
          {agrType == 'club' ? <AgrClubContent /> : <AgrContent />}
          {renderTabs()}
        </Grid>
      ) : (
        <></>
      )}

      <RACModalCard
        isOpen={doc}
        closeIcon={true}
        title={'Documents'}
        onClose={() => {
          setdoc(false), setLoaders({ ...loaders, docLoader: false });
        }}
        maxWidth="xs"
        className={`${classes.customModal1}`}
        borderRadius={'15px'}
      >
        {<AgrDocument />}
      </RACModalCard>

      <Grid item md={12} className={classes.fixedBottom}>
        <RACButton
          color="primary"
          variant="outlined"
          size="small"
          onClick={() => {
            redirect();
          }}
        >
          Cancel
        </RACButton>
      </Grid>
    </Grid>
  );
}
