import React, { useContext } from 'react';
import {
  Grid,
  Typography,
  Card,
  CardContent,
  RACTable,
  RACTableCell,
} from '@rentacenter/racstrap';
import { taxExamptDetails } from '../../../constants/CustomerConstants';
import { ReactComponent as NoRecordsFound } from '../../../assets/images/No-records.svg';
import { ReactComponent as AlertIcon } from '../../../emilyIcons/no-records-found.svg';
import { CustomerContext } from '../CustomerDependencies/CustomerContext';
import {
  formDenyPayload,
  formInsertPayload,
  formPendPayload,
  formUpdateData,
  getEditTaxExamptRow,
  getInlineEdit,
  getTaxExemptRow,
  validateDates,
} from '../CustomerDependencies/TaxExemptHelper';
import { GetCustomerDetails, updateTaxExemptCustomer } from '../../../api/user';
import { Loader } from '../CommonCompo/Loader';
import { tableHeadings } from '../../../constants/customerDetailsInterface';
import { handleTaxExemptDetails } from '../CustomerDependencies/CustomerDetailsHelperFunc';

export default function TaxExamptTab(props: any) {
  const {
    classes,
    taxExamptTableBody,
    setTaxExamptData,
    setRender,
    setErrorMessage,
    setErrorMessagePopup,
    user,
    taxExamptData,
    render,
    setLoader,
    taxExamptEditDetails,
    setTaxExamptEditId,
    setTaxExamptTableBody,
    taxExamptionEditId,
    setTaxExamptEditDetails,
    taxExemptDenialDropDown,
    setResponseStatus,
  } = useContext(CustomerContext);
  const errorMessage = 'Unable to Add Tax Details';

  const taxExamptTableHeading: tableHeadings[] = [
    { heading: 'Tax Id / SSN' },
    { heading: 'Status' },
    { heading: 'Effective Date' },
    { heading: 'Expiration Date' },
    { heading: 'Approval/Denial Date' },
    { heading: 'Denial Reason' },
    user.includes('Tax') ? { heading: 'Action' } : { heading: '' },
  ];

  const somethingWentWrong = 'Something Went Wrong';

  const inputValidation =
    taxExamptData.taxId.trim().length !== 0 && taxExamptData.status != '0';

  const updateValidation = !(
    taxExamptEditDetails?.federalTaxId !=
      taxExamptTableBody[taxExamptionEditId]?.federalTaxId ||
    taxExamptEditDetails?.taxExemptStatus !=
      taxExamptTableBody[taxExamptionEditId]?.taxExemptStatus ||
    taxExamptEditDetails?.taxExemptStatusDate !=
      taxExamptTableBody[taxExamptionEditId]?.taxExemptStatusDate ||
    taxExamptEditDetails?.submittedDate !=
      taxExamptTableBody[taxExamptionEditId]?.submittedDate ||
    taxExamptEditDetails?.expirationDate !=
      taxExamptTableBody[taxExamptionEditId]?.expirationDate ||
    taxExamptEditDetails?.taxExemptStatusReasonDesc !=
      taxExamptTableBody[taxExamptionEditId]?.taxExemptStatusReasonDesc
  );
  const initialValidation =
    taxExamptEditDetails?.federalTaxId == '' ||
    taxExamptEditDetails?.taxExemptStatus == 0;

  const NoRecordsValidation =
    taxExamptTableBody?.length == 0 && props.value.taxExemptTabStatus != 500;
  const taxExemptTabHead = () => (
    <>
      {taxExamptTableHeading.map((heading: any, index: number) => {
        return heading.heading ? (
          <RACTableCell
            key={index}
            className={`${classes.bold}  ${
              heading.heading == 'Action'
                ? classes.textCenter
                : heading.heading == 'Denial Reason'
                ? classes.width15
                : ''
            }`}
          >
            {heading.heading}
          </RACTableCell>
        ) : (
          ``
        );
      })}
    </>
  );

  const taxExemptStatus: boolean =
    taxExamptTableBody.length > 0
      ? taxExamptTableBody.find(
          (obj: any) =>
            obj.taxExemptStatus == 'PEND' || obj.taxExemptStatus == 'APP'
        )
        ? true
        : false
      : false;

  async function addNewRecord() {
    try {
      let validation = {
        validationResult: true,
        message: somethingWentWrong,
      };
      // if (taxExamptData.effectiveDate && taxExamptData.expirationDate) {
      validation = await validateDates(
        taxExamptData.effectiveDate,
        taxExamptData.expirationDate,
        taxExamptData.approvalDenailDate
      );
      // }
      if (validation.validationResult || taxExamptData.status != 'APP') {
        setLoader(true);
        props.value.setLoaderCardMessage('Validating Customer Tax Details');
        const payload = await formInsertPayload(taxExamptData, props);
        const insertTaxExamptCustomerData: any = await updateTaxExemptCustomer(
          payload
        );
        if (insertTaxExamptCustomerData?.status == 200) {
          if (insertTaxExamptCustomerData?.data) {
            props.value.setLoaderCardMessage('Updating Customer Tax Details');
            const customerDetailsResponse: any = await GetCustomerDetails(
              props.value.customerId
            );
            const taxDetails: any =
              customerDetailsResponse.data.GetCustomer.value.taxExempts;

            handleTaxExemptDetails(
              taxDetails,
              setResponseStatus,
              setTaxExamptTableBody
            );
            setLoader(false);
          } else {
            setLoader(false);
            setErrorMessage('Unable to Add the Customer Data');
            setErrorMessagePopup(true);
          }
        } else {
          setLoader(false);
          setErrorMessage('Unable to Add the Customer Data');
          setErrorMessagePopup(true);
        }
        setTaxExamptData(taxExamptDetails);
        setRender(!render);
      } else {
        setErrorMessage(validation.message);
        setErrorMessagePopup(true);
      }
    } catch (e: any) {
      setErrorMessage(errorMessage);
      setLoader(false);
      setErrorMessagePopup(true);
    }
  }

  // function getErrorMessage(insertTaxExamptCustomerData: any) {
  //   return insertTaxExamptCustomerData?.data?.errors[0]?.message
  //     ? insertTaxExamptCustomerData?.data?.errors[0]?.message
  //     : errorMessage;
  // }

  async function addUpdatedRecord() {
    try {
      let validation = {
        validationResult: true,
        message: somethingWentWrong,
      };
      validation = await validateDates(
        taxExamptEditDetails.submittedDate,
        taxExamptEditDetails.expirationDate,
        taxExamptEditDetails.taxExemptStatusDate
      );
      if (validation.validationResult) {
        setLoader(true);
        props.value.setLoaderCardMessage('Validating Customer Tax Details');
        let payload;
        if (taxExamptEditDetails.taxExemptStatus == 'DENY') {
          payload = formDenyPayload(taxExamptEditDetails, props);
        } else if (taxExamptEditDetails.taxExemptStatus == 'PEND') {
          payload = formPendPayload(taxExamptEditDetails, props);
        } else {
          payload = formUpdateData(taxExamptEditDetails, props);
        }
        const updateResponse: any = await updateTaxExemptCustomer(payload);
        if (updateResponse?.status == 200) {
          if (updateResponse?.data) {
            props.value.setLoaderCardMessage('Updating Customer Tax Details');
            const customerDetailsResponse: any = await GetCustomerDetails(
              props.value.customerId
            );
            const taxDetails: any =
              customerDetailsResponse.data.GetCustomer.value.taxExempts;
            handleTaxExemptDetails(
              taxDetails,
              setResponseStatus,
              setTaxExamptTableBody
            );
            setLoader(true);
          }
        } else {
          setErrorMessage(somethingWentWrong);
          setErrorMessagePopup(true);
        }
        setTaxExamptEditId(-1);
        setLoader(false);
      } else {
        setErrorMessage(validation.message);
        setErrorMessagePopup(true);
      }
    } catch (error: any) {
      setErrorMessage(errorMessage);
      setLoader(false);
      setErrorMessagePopup(true);
    }
  }
  //tax exampt table body part
  const taxExemptTabContent = () => (
    <>
      {user.includes('Tax')
        ? getInlineEdit(
            classes,
            taxExamptData,
            setTaxExamptData,
            taxExemptStatus,
            addNewRecord,
            props.value.taxExemptDDLoader,
            taxExemptDenialDropDown,
            inputValidation
          )
        : ''}
      {taxExamptTableBody.map((data: any, index: number) => {
        return taxExamptionEditId == index
          ? getTaxExemptRow(
              index,
              classes,
              taxExamptEditDetails,
              setTaxExamptEditDetails,
              addUpdatedRecord,
              setTaxExamptEditId,
              props.value.taxExemptDDLoader,
              taxExemptDenialDropDown,
              updateValidation,
              initialValidation
            )
          : getEditTaxExamptRow(
              data,
              index,
              classes,
              user,
              setTaxExamptEditId,
              setTaxExamptEditDetails,
              taxExamptTableBody
            );
      })}
    </>
  );

  function getCardStatus() {
    return (
      <Grid className={`${classes.textCenter} ${classes.mt5}`}>
        {NoRecordsValidation ? <NoRecordsFound /> : <AlertIcon />}
        <Typography className={`${classes.subTitle} ${classes.mb3}`}>
          {NoRecordsValidation ? 'No Records Found' : 'Something Went Wrong'}
        </Typography>
      </Grid>
    );
  }

  return (
    <Grid
      container
      md={12}
      className={`${classes.lightGrayBgColor} ${classes.p01} ${classes.paddingBottom70px}`}
    >
      <Grid container md={12}>
        <Card className={`${classes.tabsCard} ${classes.mt3}`}>
          <CardContent>
            <Grid>
              {props.value.cardLoader ? (
                <Grid className={classes.cardLoader}>
                  <Loader />
                </Grid>
              ) : taxExamptTableBody?.length != 0 || user.includes('Tax') ? (
                <RACTable
                  renderTableHead={taxExemptTabHead}
                  renderTableContent={taxExemptTabContent}
                  stickyHeader
                  className={classes.fixTableHeadAgrTrsRec}
                />
              ) : (
                getCardStatus()
              )}
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}
