import {
  CardContent,
  CircularProgress,
  Grid,
  RACTable,
} from '@rentacenter/racstrap';
import { Card } from '@rentacenter/racstrap';
import { AgreementContext } from '../AgreementContext';
import React, { useContext } from 'react';
import ErrorContent from '../ErrorMessageContent';
import { getAgreementData } from '../../../api/user';
import InfiniteScroll from 'react-infinite-scroll-component';
import { buildPayload } from '../commonFunctions';

export default function Agrsearchgrid(props: any) {
  const {
    classes,
    pageDisplay,
    hasMore,
    loaders,
    setAgreementSearchContent,
    displaySearch,
    agreementSearchContent,
    setHasMore,
    setLoaders,
    agreementSearchData,
    offset,
    setOffset,
  } = useContext(AgreementContext);

  const renderErrorContent = (content: any, status: any) => (
    <ErrorContent content={content} status={status} style={classes.mt3} />
  );

  const fetchNextData = async () => {
    try {
      setHasMore(true);
      setLoaders({ ...loaders, fetchDataLoader: true });
      const request = buildPayload(agreementSearchData, offset);
      const fetchedData: any = await getAgreementData(request);
      setOffset(offset + 20);
      if (fetchedData?.status === 200) {
        setLoaders({ ...loaders, fetchDataLoader: false });
        setAgreementSearchContent((pre: any) => [
          ...pre,
          ...fetchedData.data.agreementSearch,
        ]);
        if (fetchedData.data.agreementSearch?.length !== 20) {
          setHasMore(false);
        }
      } else {
        setHasMore(false);
      }
    } catch (e: any) {
      setLoaders({ ...loaders, fetchDataLoader: false });
      setHasMore(false);
    }
  };

  const renderTable = () => (
    <>
      {agreementSearchContent?.length > 19 ? (
        <InfiniteScroll
          next={fetchNextData}
          dataLength={agreementSearchContent?.length}
          hasMore={hasMore}
          height={
            displaySearch
              ? '51vh'
              : agreementSearchContent?.length > 19
              ? '73vh'
              : 'auto'
          }
          loader={
            loaders.fetchDataLoader ? (
              <Grid className={classes.GridLoader}>
                <CircularProgress></CircularProgress>
              </Grid>
            ) : null
          }
        >
          <RACTable
            renderTableHead={props?.renderAgreementTableHeadFn}
            renderTableContent={props?.renderAgreementTableContentFn}
          />
        </InfiniteScroll>
      ) : (
        <RACTable
          renderTableHead={props?.renderAgreementTableHeadFn}
          renderTableContent={props?.renderAgreementTableContentFn}
        />
      )}
    </>
  );

  return (
    <Grid
      container
      md={12}
      className={displaySearch === true ? `${classes.mt2}` : ''}
    >
      <Card className={`${classes.card3}`}>
        <CardContent>
          <Grid
            className={
              pageDisplay != 200
                ? classes.fixTableHeadAgrTrsRec1
                : classes.fixTableHeadAgrTrsRec
            }
          >
            {pageDisplay === 204 ? (
              renderErrorContent('No Records Found', 204)
            ) : pageDisplay === 200 ? (
              renderTable()
            ) : pageDisplay == 400 ? (
              renderErrorContent('Bad Request', 400)
            ) : pageDisplay == 500 ? (
              renderErrorContent('Something Went Wrong', 500)
            ) : (
              <></>
            )}
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
}
