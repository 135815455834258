/* eslint-disable */
import React, { createContext } from 'react';
import { Router } from 'react-router';
import { RACThemeProvider, makeStyles } from '@rentacenter/racstrap';
import clsx from 'clsx';
// eslint-disable-next-line @typescript-eslint/no-var-requires
const History = require('history');
import { ErrorBoundary } from '../components/ErrorBoundary/ErrorBoundary';
import { InContainerAuthProvider } from '../context/auth/InContainerAuthProvider';
import { StandaloneAuthProvider } from '../context/auth/StandaloneAuthProvider';
import { AuthGuard } from '../components/AuthGuard/AuthGuard';
import { CustomPropInterface } from './../index';

interface Props {
  history: typeof History;
  customProps?: CustomPropInterface;
}

export const appTestId = 'appTestId';

const useStyles = makeStyles(() => ({
  container: {
    width: '100%',
    minHeight: '89vh',
    backgroundColor: '#ecf0f3',
  },
}));

export const ContainerContext = createContext<CustomPropInterface | undefined>(
  undefined
);

export const App = ({ history, customProps }: Props) => {
  const { isRenderedByContainer } = window;
  const classes = useStyles();
  const enableLogs = window.sessionStorage.getItem('enableLogs');
  if (enableLogs != '1') {
    //eslint-disable-next-line @typescript-eslint/no-empty-function
    console.log = function () {};
  }
  return (
    <div data-testid={appTestId}>
      <RACThemeProvider>
        <ContainerContext.Provider value={customProps}>
        <div className={clsx(classes.container)}>
          <ErrorBoundary>
            <Router history={history}>
              {isRenderedByContainer ? (
                <InContainerAuthProvider>
                  <AuthGuard />
                </InContainerAuthProvider>
              ) : (
                <StandaloneAuthProvider>
                  <AuthGuard />
                </StandaloneAuthProvider>
              )}
            </Router>
          </ErrorBoundary>
        </div>
        </ContainerContext.Provider>
      </RACThemeProvider>
    </div>
  );
};
