/* eslint-disable prettier/prettier */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-var */
/* eslint-disable no-empty */
/* eslint-disable prefer-const */
/* eslint-disable no-console */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable no-debugger */
/* eslint-disable react/jsx-key */
/* eslint-disable sonarjs/no-identical-functions */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable */
import { makeStyles, RACCOLOR } from '@rentacenter/racstrap';
export const customerDetailsStyle = () => {
  const OpenSansSemiBold = 'OpenSans-semibold';
  const OpenSansBold = 'OpenSans-bold';
  const useClasses = makeStyles((theme) => ({
    '@global': {
      '*::-webkit-scrollbar': {
        width: '10px',
      },
      '*::-webkit-scrollbar-thumb': {
        backgroundColor: 'rgb(189, 189, 196)',
        borderRadius: '20px',
        border: '3px solid rgb(206, 204, 201)',
      },
      '*::-webkit-scrollbar-track': {
        background: 'rgb(245, 243, 239)',
      },
    },
    loaderBackground: {
      '& div': {
        '& div': {
          backgroundColor: 'transparent',
          boxShadow: 'none',
        },
      },
    },
    fixTableHead: {
      overflowY: 'auto',
      maxHeight: '205px',
      '& th': {
        position: 'sticky',
        top: 0,
        backgroundColor: '#ffffff',
        fontFamily: OpenSansSemiBold,
        zIndex: 1,
        padding: '0.9375rem 0.2rem',
        whiteSpace: 'nowrap',
      },
      '& tbody': {
        '& tr': {
          position: 'sticky',
          top: 10,
          backgroundColor: '#ffffff',
        },
        '& td': {
          fontFamily: OpenSansSemiBold,
          padding: '0.9375rem 0.2rem',
          color: '#4A5174',
        },
      },
    },
    fixTableHeight300: {
      maxHeight: '300px',
    },
    fixTableHeight400: {
      maxHeight: '400px',
    },
    cardHeight: {
      height: '270px',
    },
    cardAlignment: {
      position: 'absolute',
      left: '45%',
      textAlign: 'center',
      top: '23%',
    },
    font14: {
      fontSize: theme.typography.pxToRem(14),
    },

    card: {
      borderRadius: theme.typography.pxToRem(16),
      boxShadow: '0 1px 4px 0 rgba(0,0,0,.074)',
      width: '100%',
    },

    tabsCard: {
      borderRadius: theme.typography.pxToRem(16),
      boxShadow: '0 1px 4px 0 rgba(0,0,0,.074)',
      width: '100%',
      overflow: 'auto',
      height: 'auto'
    },

    tabsCardWithBottom: {
      borderRadius: theme.typography.pxToRem(16),
      boxShadow: '0 1px 4px 0 rgba(0,0,0,.074)',
      width: '100%',
      overflow: 'auto',
      height: 'auto',
      marginBottom: '8vh',
      minHeight: '220px'
    },

    tabsCard1: {
      borderRadius: '15px',
      height: 'auto',
      width: '100%',
      marginTop: '25px'
    },

    tabsCard1WithBottom: {
      borderRadius: '15px',
      height: 'auto',
      width: '100%',
      marginTop: '25px',
      marginBottom: '8vh',
      overflow: 'auto',
    },

    searchCard: {
      borderRadius: '15px',
      width: '100%',
      marginTop: theme.typography.pxToRem(8),
      height: 'auto',
      maxHeight: '90vh'
    },
    searchCardLocation: {
      borderRadius: '15px',
      width: '100%',
      marginTop: theme.typography.pxToRem(8),
      height: 'auto',
      maxHeight: '80vh',
    },
    searchCardLocation1: {
      borderRadius: '15px',
      width: '100%',
      marginTop: theme.typography.pxToRem(8),
      height: 'auto',
      maxHeight: '52vh',
    },
    customModal2: {
      '& div': {
        '& div': {
          maxWidth: '650px',
          maxHeight: '850px',
        },
      },
    },
    customModal4: {
      '& div': {
        '& div': {
          maxWidth: '710px',
          maxHeight: '850px',
        },
      },
    },
    GridLoader: {
      textAlign: 'center',
      marginBottom: theme.typography.pxToRem(32),
      fontSize: theme.typography.pxToRem(16),
      color: `${RACCOLOR.RICH_BLACK}`,
      margin: '30px 0px',
    },
    mt0: {
      marginTop: 0,
    },
    mt05: {
      marginTop: theme.typography.pxToRem(0.7),
    },

    mt1: {
      marginTop: theme.typography.pxToRem(4),
    },

    mt2: {
      marginTop: theme.typography.pxToRem(8),
    },
    mtt2: {
      marginTop: theme.typography.pxToRem(10),
      marginLeft: '10px',
    },
    mt3: {
      marginTop: theme.typography.pxToRem(16),
    },

    mt4: {
      marginTop: theme.typography.pxToRem(24),
    },
    pt4: {
      paddingTop: theme.typography.pxToRem(24),
    },

    mt45: {
      marginTop: theme.typography.pxToRem(42),
    },

    mt43: {
      marginTop: theme.typography.pxToRem(30),
      marginLeft: '10px',
    },
    mt5: {
      marginTop: theme.typography.pxToRem(48),
    },

    mt6: {
      marginTop: '14px',
    },

    height100: {
      height: '90vh',
    },
    bgcolorRed: {
      backgroundColor: 'red',
    },
    mtAuto: {
      marginTop: 'auto',
    },
    fixTableHeadAgrTrsRec: {
      overflowY: 'auto',
      '& th': {
        position: 'sticky',
        top: 0,
        backgroundColor: '#ffffff',
        fontFamily: OpenSansBold,
        zIndex: 1,
        // padding: '0.7rem 0.2rem',
        whiteSpace: 'nowrap',
        fontSize: '15px',
        fontWeight: '400',
        color: '#111111',
      },
      '& tbody': {
        '& tr': {
          position: 'sticky',
          backgroundColor: '#ffffff',
        },
        '& td': {
          fontFamily: OpenSansSemiBold,
          // padding: '0.4rem 0.2rem',
        },
      },
      disableme: {
        pointerEvents: 'none',
        opacity: 0.9,
      },
    },
    customerResultsGrid: {
      overflowY: 'auto',
      maxHeight: '700px',
      '& th': {
        position: 'sticky',
        top: 0,
        backgroundColor: '#ffffff',
        fontFamily: OpenSansBold,
        zIndex: 1,
        padding: '0.75rem 0.2rem',
        whiteSpace: 'nowrap',
        fontSize: '15px',
        fontWeight: '400',
        color: '#111111',
      },
      '& tbody': {
        '& tr': {
          position: 'sticky',
          backgroundColor: '#ffffff',
        },
        '& td': {
          fontFamily: OpenSansSemiBold,
          padding: '0.75rem 0.2rem',
        },
      },
      disableme: {
        pointerEvents: 'none',
        opacity: 0.9,
      },
    },

    ml4: {
      marginBottom: theme.typography.pxToRem(4),
    },
    ml5: {
      marginLeft: '10px',
    },
    mlm5: {
      marginLeft: '-20px',
    },
    //   margin bottom
    mb0: {
      marginBottom: 0,
    },

    mb1: {
      marginBottom: theme.typography.pxToRem(4),
    },

    mb2: {
      marginBottom: theme.typography.pxToRem(8),
    },
    mb3: {
      marginBottom: theme.typography.pxToRem(16),
    },

    mb4: {
      marginBottom: theme.typography.pxToRem(24),
    },

    mb5: {
      marginBottom: theme.typography.pxToRem(48),
    },
    mb6: {
      marginBottom: theme.typography.pxToRem(96),
    },
    mb32: {
      marginBottom: theme.typography.pxToRem(32),
    },
    mbAuto: {
      marginBottom: 'auto',
    },
    //   padding
    p0: {
      padding: 0,
    },

    p1: {
      padding: theme.typography.pxToRem(4),
    },

    p2: {
      padding: theme.typography.pxToRem(8),
    },
    pt0: {
      padding: '0% 4%',
    },
    marginLeft15: {
      marginLeft: '-20px',
    },
    pm4: {
      marginLeft: '-4px',
    },
    ml26: {
      marginLeft: '-31px',
    },
    p3: {
      padding: theme.typography.pxToRem(16),
    },

    p4: {
      padding: theme.typography.pxToRem(24),
    },

    p5: {
      padding: theme.typography.pxToRem(48),
    },
    p32: {
      padding: theme.typography.pxToRem(32),
    },

    padding4: {
      padding: '20px',
    },

    ms0: {
      marginLeft: 0,
    },

    ms1: {
      marginLeft: theme.typography.pxToRem(4),
    },

    ms2: {
      marginLeft: theme.typography.pxToRem(8),
    },

    ms3: {
      marginLeft: theme.typography.pxToRem(16),
    },

    ms4: {
      marginLeft: theme.typography.pxToRem(24),
    },

    ms5: {
      marginLeft: theme.typography.pxToRem(48),
    },

    mL1: {
      marginLeft: '10px',
    },

    mL15: {
      marginLeft: '15px',
    },

    mR1: {
      marginRight: '10px',
    },

    msAuto: {
      marginLeft: 'auto',
    },

    textCenter: {
      textAlign: 'center',
    },
    textRight: {
      textAlign: 'right',
    },

    subTitle: {
      color: '#212529',
      fontFamily: OpenSansBold,
      fontSize: theme.typography.pxToRem(14),
      marginBottom: theme.typography.pxToRem(10),
    },
    fontSize18: {
      fontSize: theme.typography.pxToRem(18),
    },
    fontSizeSearchTable: {
      fontSize: '14px',
    },
    width3: {
      width: '3%',
    },
    semiBold: {
      fontFamily: OpenSansSemiBold,
    },

    bold: {
      fontFamily: 'OpenSans-Bold',
    },

    regular: {
      fontFamily: 'OpenSans-regular',
    },

    formLabel: {
      // marginBottom: '0.2rem',
      color: '#000',
      fontFamily: OpenSansSemiBold,
      fontSize: theme.typography.pxToRem(14),
    },
    floatLeft: {
      float: 'left',
    },
    title: {
      color: '#000',
      fontFamily: OpenSansBold,
      fontWeight: 'bold',
      fontSize: '16px',
      marginBottom: theme.typography.pxToRem(10),
    },
    tabsWidth: {
      '& div': {
        width: '100%',
      },
    },
    height2: {
      height: '85vh',
    },
    height1: {
      height: '380px',
    },
    mt15: {
      marginTop: '100px',
    },
    mt10: {
      marginTop: '150px',
    },
    backgroundColorWhite: {
      backgroundColor: 'white',
    },
    overflowAuto: {
      overflowY: 'auto',
    },
    fixedBottom: {
      position: 'fixed',
      right: 0,
      bottom: 0,
      left: 0,
      zIndex: 1030,
      backgroundColor: `${RACCOLOR.WHITE}`,
      boxShadow: '0 0.5rem 1rem rgb(0 0 0 / 15%)',
      padding: '0.5rem',
    },
    typoSemiBoldFont: {
      fontFamily: OpenSansSemiBold,
      marginTop: '1px',
      left: '9px',
      padding: '10px 0px',
    },
    typoBoldFont: {
      fontFamily: OpenSansBold,
      marginTop: '1px',
      left: '9px',
      padding: '10px 0px',
    },
    typoRegularFont: {
      fontFamily: 'OpenSans-regular',
      marginTop: '1px',
      left: '9px',
      padding: '10px 0px',
    },
    textBlue: {
      color: `${RACCOLOR.NORMAL_BLUE}`,
    },
    textBlack: {
      color: 'black',
    },
    pagination: {
      display: 'flex',
      listStyle: 'none',
      textDecoration: 'none',
      fontFamily: OpenSansSemiBold,
      listStyleType: 'none',
      fontSize: theme.typography.pxToRem(14),
      justifyContent: 'flex-end',
      cursor: 'pointer',
    },
    pageLink: {
      position: 'relative',
      display: 'block',
      textDecoration: 'none',
      padding: '5px 0px',
      width: '26px',
      textAlign: 'center',
    },
    paginationPrevNextDisabled: {
      color: 'lightgray !important',
      padding: '5px 10px',
      display: 'inline-block',
    },
    pageActive: {
      backgroundColor: '#2468FF',
      color: '#fff',
      borderRadius: '50%',
    },
    paginationPrevNext: {
      color: '#000',
      padding: '5px 10px',
      display: 'inline-block',
    },
    textDarkSlateBlue: {
      color: `${RACCOLOR.INDEPENDENCE}`,
    },
    h1: {
      height: '15px',
    },
    width5: {
      width: '5%',
    },
    width8: {
      width: '8%',
    },
    width10: {
      width: '10%',
    },
    width15: {
      width: '15%',
    },
    width25: {
      width: '25%',
    },
    iconWidth: {
      width: '50%',
      height: '20px',
      cursor: 'pointer',
    },
    arrowIcon: {
      width: '80%',
    },
    actionIconWidth: {
      width: '5px',
    },
    racpadLink: {
      color: '#2179FE',
      fontSize: theme.typography.pxToRem(14),
      textDecoration: 'none',
      cursor: 'pointer',
      fontFamily: OpenSansSemiBold,
    },
    pointer: {
      cursor: 'pointer',
    },
    floatRight: {
      float: 'right',
    },
    linkColor: {
      color: `${RACCOLOR.BLUE_CRAYOLA}`,
      cursor: 'pointer',
    },
    lightGrayBgColor: {
      backgroundColor: '#ecf0f3',
    },
    mr2: {
      marginRight: '2%',
    },
    mx1: {
      marginRight: theme.typography.pxToRem(4),
      marginLeft: theme.typography.pxToRem(4),
    },
    pb4: {
      paddingBottom: theme.typography.pxToRem(24),
    },
    errorModelWidth: {
      width: '200px',
    },
    p01: {
      padding: '0 1%',
    },
    p10px0px: {
      padding: '10px 0px',
    },
    p10p25: {
      padding: '10px 25px',
    },
    blueCryolaColor: {
      color: `${RACCOLOR.BLUE_CRAYOLA}`,
    },
    backgroundWhite: {
      background: 'transparent !important',
      boxShadow: 'none !important',
    },
    categoryTag: {
      backgroundColor: 'rgba(255, 50, 50, 0.788)',
      color: 'white',
      display: 'inline',
      padding: '5px 10px',
      borderRadius: '5px',
      border: 'none',
    },
    customModal: {
      '& div': {
        '& div': {
          maxWidth: '1200px',
        },
      },
    },
    customModal3: {
      '& div': {
        '& div': {
          maxWidth: '700px',
          maxHeight: '850px',
        },
      },
    },
    check: {
      '& div': {
        '& div': {

        },
        '& div': {
          maxWidth: '30% !important'
        }
      }
    },
    cancelBtn: {
      padding: '7px 30px',
      margin: '10px',
    },
    pagePadding: {
      padding: '0 1% 1% 1%',
      width: '100%',
    },
    paginationGrid: {
      display: 'flex',
      justifyContent: 'right',
      alignItems: 'center',
      padding: '20px',
    },
    AgrLeftColor: {
      borderLeft: '3px solid #8BEEE9',
      borderTopRightRadius: '5px',
      borderBottomRightRadius: '5px',
      marginRight: '5px',
      height: '130%',
    },
    cursorStyle: {
      cursor: 'pointer',
    },
    wordWrap: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    loaderGridStyle: {
      position: 'fixed',
      background: '#f7f5f5',
      width: '100%',
      height: '100%',
      top: 0,
      left: 0,
      opacity: 0.6,
      zIndex: 2000,
      textAlign: 'center',
      margin: '0px 0px',
    },
    redColor: {
      borderLeft: '3px solid #FF3333',
      borderTopRightRadius: '5px',
      borderBottomRightRadius: '5px',
      marginRight: '5px',
      height: '130%',
    },
    loaderStyle: {
      display: 'block',
      position: 'fixed',
      zIndex: 9999999,
      top: '40%',
      right: '50%',
    },
    sortIconPosition: {
      marginLeft: '5px',
      paddingTop: '0.5px',
      cursor: 'pointer',
    },
    phoneTooltipIcon: {
      height: '15px',
      marginLeft: '6px',
      marginBottom: '-2.5px',
      cursor: 'pointer',
    },
    customerCardLoader: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '240px',
    },
    cardLoader: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '180px',
    },
    red: {
      backgroundColor: 'red',
    },
    tabstest: {
      '& .Mui-selected': {
        '& .MuiTab-wrapper': {
          color: '#0d6efd'
        },
      },
    },
    width0: {
      maxWidth: 0
    },
    option: {
      whiteSpace: 'normal',
      width: '205px'
    },
    width100: {
      width: '100%'
    },
    width16: {
      width: '16%'
    },
    width125: {
      width: '33.3%'
    },
    width70: {
      width: '70%'
    },
    width30: {
      width: '30%'
    },
    hoverStyleRemover: {
      '& .MuiButtonBase-root:hover': {
        backgroundColor: 'transparent',
      },
    },
    buttonDisableColor: {
      position: 'relative',
      '&[disabled]::after': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(255, 255, 255, 0.5)',
        zIndex: 1,
      },
    },
    width50: {
      width: '50%',
    },
    colorGray: {
      color: 'gray',
    },
    hideScrollbar: {
      '&::-webkit-scrollbar': {
        display: 'none', // Hide scrollbar for webkit browsers
      },
      '&': {
        msOverflowStyle: 'none', // For Internet Explorer
        scrollbarWidth: 'none', // For Firefox
      },
    }, 
    pb70px: {
      paddingBottom: '20px'
    }, 
    paddingBottom70px: {
      paddingBottom: '70px'
    },
    fixTableHeadAgrTrsRec1: {
      '& th': {
        top: 0,
        backgroundColor: '#ffffff',
        fontFamily: OpenSansBold,
        zIndex: 1,
        // padding: '0.7rem 0.2rem',
        whiteSpace: 'nowrap',
        fontSize: '15px',
        fontWeight: '400',
        color: '#111111',
      },
      '& tbody': {
        '& tr': {
          position: 'sticky',
          backgroundColor: '#ffffff',
        },
        '& td': {
          fontFamily: OpenSansSemiBold,
          // padding: '0.4rem 0.2rem',
        },
      },
      disableme: {
        pointerEvents: 'none',
        opacity: 0.9,
      },
    }
  }));
  return useClasses();
};
