import {
  Card,
  CardContent,
  Grid,
  RACTable,
  RACTableCell,
  RACTableRow,
} from '@rentacenter/racstrap';
import { GlobalStyles } from '../../../JsStyles/globalStyles';
import React, { useContext } from 'react';
import { AgreementContext } from './../AgreementContext';
import ErrorContent from './../ErrorMessageContent';
import { activityHeadings } from '../../../constants/constants';
import { ReactComponent as Sort } from '../../../Icons/Sort.svg';
import { sorting } from './../commonFunctions';
import { useLocation } from 'react-router-dom';

export default function AgrActivity() {
  const classes = GlobalStyles();
  const {
    sortOrder,
    activityPageDisplay,
    activityDetails,
    setSortOrder,
    setPaymentDetails,
    setActivityDetails,
    setInventoryDetails,
  } = useContext(AgreementContext);

  const search = useLocation().search;
  const queryParams = new URLSearchParams(search);
  const agrType = queryParams.get('agrType');

  const activityTableContentHelperFn = (obj: any) => (
    <>
      <RACTableCell
        className={`${classes.semiBoldFont} ${classes.textDarkSlateBlue} ${classes.whitebackground} ${classes.width6}`}
      >
        {obj?.coWorker ? obj?.coWorker : '-'}
      </RACTableCell>
      <RACTableCell
        className={`${classes.semiBoldFont} ${classes.textDarkSlateBlue} ${classes.whitebackground} ${classes.width6}`}
      >
        {obj?.createdSource ? obj?.createdSource : '-'}
      </RACTableCell>
    </>
  );

  const activityTableContentFn = () => (
    <>
      {activityDetails?.map((obj: any, index: any) => {
        const activityDate =
          agrType == 'club' ? obj?.activityDateTime : obj?.activityDate;
        return (
          <RACTableRow key={index}>
            <RACTableCell
              className={`${classes.semiBoldFont} ${classes.textDarkSlateBlue} ${classes.whitebackground} ${classes.width6}`}
            >
              {activityDate ? obj?.activityDate + ' ' + 'CST' : '-'}
            </RACTableCell>
            <RACTableCell
              className={`${classes.semiBoldFont} ${classes.textDarkSlateBlue} ${classes.whitebackground} ${classes.width6}`}
            >
              {obj?.activityType ? obj?.activityType : '-'}
            </RACTableCell>
            <RACTableCell
              className={`${classes.semiBoldFont} ${classes.textDarkSlateBlue} ${classes.whitebackground} ${classes.width6}`}
            >
              {agrType == 'club'
                ? obj?.status
                  ? obj?.status
                  : '-'
                : obj?.description
                ? obj?.description
                : '-'}
            </RACTableCell>
            {activityTableContentHelperFn(obj)}
          </RACTableRow>
        );
      })}
    </>
  );

  const activityTableHeadFn = () => (
    <>
      {activityHeadings.map((obj: any, index: any) => {
        agrType == 'club' && obj?.heading == 'Description'
          ? (obj.heading = 'Status')
          : obj?.heading;
        return activityDetails.length > 1 && obj?.heading === 'Date' ? (
          <RACTableCell key={index}>
            {obj?.heading}{' '}
            {!sortOrder.dateActSortOrder ? (
              <Sort
                className={`${classes.sortUp}`}
                onClick={() =>
                  sorting(
                    activityDetails,
                    'desc',
                    'activityDate',
                    setSortOrder,
                    sortOrder,
                    setPaymentDetails,
                    setActivityDetails,
                    setInventoryDetails
                  )
                }
              />
            ) : (
              <Sort
                className={`${classes.sort}`}
                onClick={() =>
                  sorting(
                    activityDetails,
                    'asc',
                    'activityDate',
                    setSortOrder,
                    sortOrder,
                    setPaymentDetails,
                    setActivityDetails,
                    setInventoryDetails
                  )
                }
              />
            )}
          </RACTableCell>
        ) : (
          <RACTableCell key={index}>{obj?.heading}</RACTableCell>
        );
      })}
    </>
  );

  return (
    <Grid
      container
      md={12}
      className={`${classes.globalBackgroundColor} ${classes.padding1} ${classes.paddingBottom70px}`}
    >
      <Card className={classes.card5}>
        <CardContent>
          <Grid item md={12}>
            {activityPageDisplay == 204 ? (
              <>
                <ErrorContent
                  content={'No Records Found'}
                  status={204}
                  style={classes.mt3}
                />
              </>
            ) : activityPageDisplay == 200 ? (
              <RACTable
                className={classes.fixTableHeadAgrTrsRec2}
                renderTableHead={() => activityTableHeadFn()}
                renderTableContent={activityTableContentFn}
                stickyHeader
              />
            ) : activityPageDisplay == 400 ? (
              <>
                <ErrorContent
                  content={'Bad Request'}
                  status={500}
                  style={classes.mt3}
                />
              </>
            ) : activityPageDisplay == 500 ? (
              <>
                <ErrorContent
                  content={'Something Went Wrong'}
                  status={500}
                  style={classes.mt3}
                />
              </>
            ) : (
              <></>
            )}
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
}
