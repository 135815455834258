/* eslint-disable */
import {
  Card,
  CardContent,
  CircularProgress,
  Grid,
  RACTable,
  RACTableCell,
  RACTableRow,
  Typography,
} from '@rentacenter/racstrap';
import { InventorySearchContext } from '../../../context/Inventory/InventorySearchContext';
import React, { useContext, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { getInventorySearch } from '../../../api/user';
import { ReactComponent as SortDesc } from '../Icons/SortDown.svg';
import { ReactComponent as SortAsc } from '../Icons/SortUp.svg';
import { useHistory } from 'react-router-dom';
import {
  conditionType,
  formatCurrency,
  isValuePresent,
  sortNumber,
  truncString,
} from '../../../utils/InventoryUtils';

export function ResultWidget() {
  const {
    classes,
    setSearchRequest,
    searchRequest,
    filteredInventories,
    setFilteredInventories,
    showFilter,
    setShowError,
    gridLoader,
  } = useContext(InventorySearchContext);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [loader, setLoader] = useState<boolean>(false);
  const [sort, setSort] = useState<boolean>(true);
  const history = useHistory();
  const InventoryResultHeader = [
    'Item #',
    'Location',
    'Department',
    'Sub-Department',
    'Brand',
    'Bracket',
    'Model #',
    'Serial #',
    'Rate',
    'Term',
    'Status',
    'Condition',
    'RV',
    'Days Idle',
  ];

  const renderInventoryHeader = () => (
    <>
      {InventoryResultHeader.map((obj: any, index: any) => {
        if (obj == 'Item #') {
          return (
            <RACTableCell key={index}>
              {obj}{' '}
              {filteredInventories?.length <= 1 ? (
                <></>
              ) : sort ? (
                <SortDesc
                  onClick={() => {
                    sortNumber(
                      'asc',
                      'inventoryNumber',
                      filteredInventories,
                      setFilteredInventories
                    );
                    setSort(!sort);
                  }}
                />
              ) : (
                <SortAsc
                  onClick={() => {
                    sortNumber(
                      'desc',
                      'inventoryNumber',
                      filteredInventories,
                      setFilteredInventories
                    );
                    setSort(!sort);
                  }}
                />
              )}
            </RACTableCell>
          );
        }
        return <RACTableCell key={index}>{obj}</RACTableCell>;
      })}
    </>
  );

  const resultGridHelper2 = (obj: any, path: any) => (
    <>
      <RACTableCell
        className={`${classes.formValue}`}
        style={{ width: '6%' }}
        onClick={() => {
          obj.inventoryNumber ? redirect(path) : null;
        }}
      >
        {obj.conditionRefCode
          ? truncString(conditionType(obj.conditionRefCode), 10, '...')
          : '-'}
      </RACTableCell>

      <RACTableCell
        className={`${classes.formValue}`}
        style={{ width: '5%' }}
        onClick={() => {
          obj.inventoryNumber ? redirect(path) : null;
        }}
      >
        {obj.remainingValue ? formatCurrency(obj.remainingValue) : '-'}
      </RACTableCell>

      <RACTableCell
        className={`${classes.formValue}`}
        style={{ width: '5%' }}
        onClick={() => {
          obj.inventoryNumber ? redirect(path) : null;
        }}
      >
        {isValuePresent(obj.daysIdle) && obj.statusEn != 'On Rent' ? obj.daysIdle : '-'}
      </RACTableCell>
    </>
  );

  const resultGridHelper1 = (obj: any, path: any) => (
    <>
      <RACTableCell
        className={`${classes.formValue}`}
        style={{ width: '5%' }}
        onClick={() => {
          obj.inventoryNumber ? redirect(path) : null;
        }}
      >
        {obj.weeklyRate ? formatCurrency(obj.weeklyRate) : '-'}
      </RACTableCell>

      <RACTableCell
        className={`${classes.formValue}`}
        style={{ width: '3%' }}
        onClick={() => {
          obj.inventoryNumber ? redirect(path) : null;
        }}
      >
        {obj.weeklyTerm ? obj.weeklyTerm : '-'}
      </RACTableCell>

      <RACTableCell
        className={`${classes.formValue}`}
        style={{ width: '6%' }}
        title={obj.statusEn}
        onClick={() => {
          obj.inventoryNumber ? redirect(path) : null;
        }}
      >
        {obj.statusEn ? truncString(obj.statusEn, 11, '...') : '-'}
      </RACTableCell>

      {resultGridHelper2(obj, path)}
    </>
  );

  const resultGridHelper = (obj: any, path: any) => (
    <>
      <RACTableCell
        className={`${classes.formValue}`}
        style={{ width: '10%' }}
        title={obj.subDepartmentsEn}
        onClick={() => {
          obj.inventoryNumber ? redirect(path) : null;
        }}
      >
        {truncString(obj.subDepartmentsEn, 15, '...')}
      </RACTableCell>

      <RACTableCell
        className={`${classes.formValue}`}
        style={{ width: '6%' }}
        title={obj.brandName}
        onClick={() => {
          obj.inventoryNumber ? redirect(path) : null;
        }}
      >
        {obj.brandName ? truncString(obj.brandName, 7, '...') : '-'}
      </RACTableCell>

      <RACTableCell
        className={`${classes.formValue}`}
        style={{ width: '8%' }}
        title={obj.bracketEn}
        onClick={() => {
          obj.inventoryNumber ? redirect(path) : null;
        }}
      >
        <Typography>{truncString(obj.bracketEn, 14, '...')}</Typography>
      </RACTableCell>

      <RACTableCell
        className={`${classes.formValue}`}
        style={{ width: '6%' }}
        title={obj.modelNumber}
        onClick={() => {
          obj.inventoryNumber ? redirect(path) : null;
        }}
      >
        {obj.modelNumber ? truncString(obj.modelNumber, 11, '...') : '-'}
      </RACTableCell>

      <RACTableCell
        className={`${classes.formValue}`}
        style={{ width: '6%' }}
        title={obj.serialNumber ? obj.serialNumber : null}
        onClick={() => {
          obj.inventoryNumber ? redirect(path) : null;
        }}
      >
        {obj.serialNumber ? truncString(obj.serialNumber, 10, '...') : '-'}
      </RACTableCell>
      {resultGridHelper1(obj, path)}
    </>
  );

  const renderInventoryResult = () => (
    <>
      {gridLoader ? (
        <RACTableRow>
          <RACTableCell colSpan={12}>
            <Grid className={`${classes.centerAlign}`}>
              <CircularProgress />
            </Grid>
          </RACTableCell>
        </RACTableRow>
      ) : (
        filteredInventories.map((obj: any, index: any) => {
          const path = `/operations/inventory/${obj.inventoryNumber}/${obj.inventoryId}/details`;
          return (
            <RACTableRow key={index} className={`${classes.whitebackground}`}>
              <RACTableCell
                key={index}
                style={{ width: '7%' }}
                onClick={() => {
                  obj.inventoryNumber ? redirect(path) : null;
                }}
              >
                {obj.inventoryNumber ? (
                  <Typography className={`${classes.racpadLink}`}>
                    {obj.inventoryNumber}
                  </Typography>
                ) : (
                  '-'
                )}
              </RACTableCell>

              <RACTableCell
                style={{ width: '5%' }}
                onClick={() => {
                  obj.storeNumber
                    ? redirect(`/operations/store/${obj?.storeNumber}`)
                    : null;
                }}
              >
                {obj.storeNumber ? (
                  <Typography className={`${classes.racpadLink}`}>
                    {' '}
                    {obj.storeNumber}
                  </Typography>
                ) : (
                  '-'
                )}
              </RACTableCell>

              <RACTableCell
                style={{ width: '8%' }}
                className={`${classes.formValue}`}
                title={obj.departmentsEn}
                onClick={() => {
                  obj.inventoryNumber ? redirect(path) : null;
                }}
              >
                {truncString(obj.departmentsEn, 11, '...')}
              </RACTableCell>

              {resultGridHelper(obj, path)}
            </RACTableRow>
          );
        })
      )}
    </>
  );

  const redirect = (path: any) => {
    history.push({
      pathname: path,
    });
  };

  const fetchNextData = async () => {
    try {
      setLoader(true);
      const inventorySearchResponse = await getInventorySearch(searchRequest);

      if (inventorySearchResponse && inventorySearchResponse?.status == 200) {
        if (inventorySearchResponse?.data?.response.length < 25) {
          setHasMore(false);
        }
        setFilteredInventories([
          ...filteredInventories,
          ...inventorySearchResponse.data.response,
        ]);
        setSearchRequest({
          ...searchRequest,
          setNumber: searchRequest.setNumber + 1,
        });
      } else {
        setShowError(true);
      }
      setLoader(false);
    } catch (e: any) {
      setShowError(true);
    }
  };

  return (
    <div>
      <Card className={`${classes.searchCard}`}>
        <CardContent>
          <InfiniteScroll
            next={fetchNextData}
            dataLength={filteredInventories?.length}
            hasMore={filteredInventories?.length < 25 ? false : hasMore}
            height={showFilter && !gridLoader ? '40vh' : !gridLoader && filteredInventories?.length > 19 ? '73vh' : 'auto'}
            loader={
              loader && !gridLoader ? (
                <Grid className={`${classes.InfiniteGrid}`}>
                  <CircularProgress />
                </Grid>
              ) : null
            }
          >
            <RACTable
              renderTableHead={renderInventoryHeader}
              renderTableContent={renderInventoryResult}
              stickyHeader={true}
              className={classes.fixTableHeadAgrTrsRec}
            />
          </InfiniteScroll>
        </CardContent>
      </Card>
    </div>
  );
}
