/* eslint-disable sonarjs/cognitive-complexity */
import {
  Grid,
  Typography,
  CircularProgress,
  Card,
  CardContent,
  RACTable,
  RACTableCell,
  RACTableRow,
} from '@rentacenter/racstrap';

import { ReactComponent as DetailsIcon } from './../../../emilyIcons/More info.svg';
import {
  PaymentHistory,
  WorkHistory,
} from '../../../constants/customerDetailsInterface';
import { ReactComponent as NoRecordsFound } from '../../../assets/images/No-records.svg';
import { ReactComponent as SortDownIcon } from './../../../emilyIcons/Sort.svg';
import { ReactComponent as SortUpIcon } from '../../../assets/images/sort-up-icon.svg';
import InfiniteScroll from 'react-infinite-scroll-component';
import React, { useContext } from 'react';
import { ReactComponent as AlertIcon } from './../../../emilyIcons/no-records-found.svg';
import {
  GetCustomerPaymentHistory,
  GetCustomerWorkHistory,
} from '../../../api/user';
import {
  formPaymentHistoryToWorkHistoryData,
  formate,
  formatePayment,
  getWorkHistoryData,
} from '../CustomerDependencies/CustomerDetailsHelperFunc';
import { workHistoryTableHeading } from '../../../constants/CustomerConstants';
import { CustomerContext } from '../CustomerDependencies/CustomerContext';
import { Loader } from '../CommonCompo/Loader';

export default function WorkHistoryTab(props: any) {
  const limit = 25;
  const hasMore = props.value.hasMore;
  const fetchDataLoader = props.value.fetchDataLoader;
  const setFetchDataLoader = props.value.setFetchDataLoader;
  const setOffset = props.value.setOffset;
  const setHasMore = props.value.setHasMore;
  const offset = props.value.offset;
  const paymentOffset = props.value.paymentHistoryOffset;
  const setPaymentOffet = props.value.setPaymentHistoryOffset;
  const paymentHasMore = props.value.paymentHasMore;
  const setPaymentHasMore = props.value.setPaymentHasMore;
  const paymentFetchDataLoader = props.value.paymentFetchDataLoader;
  const setPaymentFetchDataLoader = props.value.setPaymentFetchDataLoader;
  const { toggleObject, setToggleObj, classes } = useContext(CustomerContext);

  const workHistoryTableBody = props.value.tableBodyOne;

  const workHistoryTabHead = () => (
    <>
      {workHistoryTableHeading?.map((heading: any, index: number) => {
        return (
          <RACTableCell
            className={`${classes.bold}  ${
              heading.heading == 'Action' ? classes.textCenter : ''
            }`}
            key={index}
          >
            {heading.heading}
            {index == 0 ? (
              !toggleObject.workHistoryDate ? (
                <SortDownIcon
                  className={classes.sortIconPosition}
                  onClick={() =>
                    setToggleObj({ ...toggleObject, workHistoryDate: true })
                  }
                />
              ) : (
                <SortUpIcon
                  className={classes.sortIconPosition}
                  onClick={() =>
                    setToggleObj({ ...toggleObject, workHistoryDate: false })
                  }
                />
              )
            ) : (
              ''
            )}
          </RACTableCell>
        );
      })}
    </>
  );

  const workHistoryTabContent = () => (
    <>
      {workHistoryTableBody?.map((data: any, index: number) => {
        return (
          <RACTableRow style={{ backgroundColor: 'white' }} key={index}>
            <RACTableCell
              className={`${classes.semiBold}  ${classes.textDarkSlateBlue}`}
            >
              {data?.activityDate
                ? `${data.activityDate}`.slice(0, 20) + ' CST'
                : '-'}
            </RACTableCell>
            <RACTableCell
              className={`${classes.semiBold}  ${classes.textDarkSlateBlue}`}
            >
              {data?.callResultType ? data?.callResultType : '-'}
            </RACTableCell>
            <RACTableCell
              className={`${classes.semiBold}  ${classes.textDarkSlateBlue}`}
            >
              {data?.daysLate ? data.daysLate : '-'}
            </RACTableCell>
            <RACTableCell
              className={`${classes.semiBold}  ${classes.textDarkSlateBlue}`}
            >
              {data?.phoneNumberDialed ? data?.phoneNumberDialed : '-'}
            </RACTableCell>
            <RACTableCell
              className={`${classes.semiBold}  ${classes.textDarkSlateBlue}`}
            >
              {data?.personalReference
                ? data.personalReference.description
                : '-'}
            </RACTableCell>
            <RACTableCell
              className={`${classes.semiBold}  ${classes.textDarkSlateBlue}`}
            >
              {data?.coWorker ? data?.coWorker : '-'}
            </RACTableCell>
            <RACTableCell
              className={`${classes.semiBold}  ${classes.textDarkSlateBlue} ${classes.textCenter}`}
            >
              <DetailsIcon
                className={classes.iconWidth}
                onClick={() => {
                  props.value.setWorkHistoryModel(true);
                  props.value.setViewDetailsIndex(index);
                }}
              />
            </RACTableCell>
          </RACTableRow>
        );
      })}
    </>
  );

  const fetchNextData = async () => {
    try {
      setOffset(offset + 25);
      setPaymentOffet(paymentOffset + 25);
      if (hasMore) {
        getWorkHistoryDatas();
      }
      if (paymentHasMore) {
        getPaymentHistoryData();
      }
    } catch (e: any) {
      setFetchDataLoader(false);
      setPaymentFetchDataLoader(false);
      setHasMore(false);
      setPaymentHasMore(false);
    }
  };

  async function getWorkHistoryDatas() {
    setFetchDataLoader(true);
    let workHistory = await GetCustomerWorkHistory(
      props.value.customerId,
      limit,
      offset
    );
    if (workHistory?.status == 200 || workHistory?.status == 204) {
      setFetchDataLoader(false);
      workHistory = workHistory?.data;
      if (Object.keys(workHistory).length != 0) {
        if (workHistory?.accountActivities) {
          const workHistoryDetails: WorkHistory[] = [];
          workHistory?.accountActivities.forEach((item: any) => {
            const data: WorkHistory = getWorkHistoryData(item);
            workHistoryDetails.push(data);
          });
          props.value.setWorkHistoryTableBody((prev: any) => [
            ...prev,
            ...workHistoryDetails,
          ]);
        } else {
          setHasMore(false);
          setFetchDataLoader(false);
        }
      } else {
        setHasMore(false);
        setFetchDataLoader(false);
      }
      if (workHistory?.accountActivities?.length !== 25) {
        setFetchDataLoader(false);
        setHasMore(false);
      }
    } else {
      setFetchDataLoader(false);
      setHasMore(false);
    }
  }

  async function getPaymentHistoryData() {
    setPaymentFetchDataLoader(true);
    let paymentHistory = await GetCustomerPaymentHistory(
      props.value.customerId,
      limit,
      paymentOffset
    );
    if (paymentHistory?.status == 200 || paymentHistory?.status == 204) {
      paymentHistory = paymentHistory?.data?.value?.customerPaymentHistory;
      setPaymentFetchDataLoader(false);

      const paymentDetails: PaymentHistory[] = [];

      paymentHistory.forEach((element: any) => {
        const data: PaymentHistory = {
          receiptId: element?.receiptId,
          store: element?.store,
          paymentOrigin: element?.paymentOrigin,
          tenderType: element?.tenderType,
          daysExt: element?.daysExt,
          daysLate: element?.daysLate,
          datePaid: formate(element),
          nextDueDate: element?.nextDueDate,
          actualTerm: element?.actualTerm,
          rentPaid: element?.rentPaid,
          closeDate: element?.closeDate,
          oldDueDate: element?.oldDueDate,
          netRent: element?.netRent,
          clubAmount: element?.clubAmount,
          ldw: element?.ldw,
          lateFee: element?.lateFee,
          discount: element?.discount,
          other: element?.other,
          tax: element?.tax,
          totalPayment: element?.totalPayment
            ? formatePayment(element?.totalPayment)
            : '-',
          nsf: element?.nsf,
          cc4Digit: element?.cc4Digit,
        };
        paymentDetails.push(data);
      });

      const workHistoryArray =
        formPaymentHistoryToWorkHistoryData(paymentDetails);
      props.value.setPaymentHistoryTableBody((prev: any) => [
        ...prev,
        ...paymentDetails,
      ]);
      props.value.setWorkHistoryTableBody((prev: any) => [
        ...prev,
        ...workHistoryArray,
      ]);

      if (paymentHistory?.length !== 10) {
        setPaymentFetchDataLoader(false);
        setPaymentHasMore(false);
      }
    } else {
      setPaymentFetchDataLoader(false);
      setPaymentHasMore(false);
    }
  }

  const renderWorkHistoryTab = () =>
    workHistoryTableBody?.length > 24 ? (
      <InfiniteScroll
        className={`${classes.hideScrollbar}`}
        next={fetchNextData}
        dataLength={workHistoryTableBody?.length}
        hasMore={hasMore || paymentHasMore}
        height={
          `${
            !hasMore || !paymentHasMore
              ? (workHistoryTableBody?.length + 1) * 55
              : workHistoryTableBody?.length * 55 - 100
          }px` || 'auto'
        }
        loader={
          fetchDataLoader || paymentFetchDataLoader ? (
            <Grid className={classes.GridLoader}>
              <CircularProgress></CircularProgress>
            </Grid>
          ) : null
        }
      >
        <RACTable
          renderTableHead={workHistoryTabHead}
          renderTableContent={workHistoryTabContent}
          stickyHeader
          className={classes.fixTableHeadAgrTrsRec}
        />
      </InfiniteScroll>
    ) : props.value.status == 200 ? (
      <RACTable
        renderTableHead={workHistoryTabHead}
        renderTableContent={workHistoryTabContent}
        stickyHeader
        className={classes.fixTableHeadAgrTrsRec}
      />
    ) : props.value.status == 204 ? (
      <Grid className={`${classes.textCenter} ${classes.mt5}`}>
        <NoRecordsFound />
        <Typography className={`${classes.subTitle} ${classes.mb3}`}>
          No Records Found
        </Typography>
      </Grid>
    ) : props.value.status == 400 ? (
      <Grid className={`${classes.textCenter} ${classes.mt5}`}>
        <AlertIcon />
        <Typography className={`${classes.subTitle} ${classes.mb3}`}>
          Bad Request
        </Typography>
      </Grid>
    ) : (
      <Grid className={`${classes.textCenter} ${classes.mt5}`}>
        <AlertIcon />
        <Typography className={`${classes.subTitle} ${classes.mb3}`}>
          Something Went Wrong
        </Typography>
      </Grid>
    );

  return (
    <Grid
      container
      md={12}
      className={`${classes.lightGrayBgColor} ${classes.p01} ${classes.pb70px}`}
    >
      <Card className={`${classes.tabsCard1WithBottom} ${classes.mt3}`}>
        <CardContent>
          {props.value.cardLoader ? (
            <Grid className={classes.cardLoader}>
              <Loader />
            </Grid>
          ) : (
            <Grid>{renderWorkHistoryTab()}</Grid>
          )}
        </CardContent>
      </Card>
    </Grid>
  );
}
